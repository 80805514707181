import { Component, OnInit } from '@angular/core';
import { AlertController, Platform, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { ServiceforallService } from '../serviceforall.service';
import { StorageService } from '../services/storage.service';
@Component({
  selector: 'app-sharemodal',
  templateUrl: './sharemodal.page.html',
  styleUrls: ['./sharemodal.page.scss'],
})
export class SharemodalPage implements OnInit {
  url: any;
  user:any;
  message: any;
  user_id: any;
  constructor(private iab: InAppBrowser,
    public storage: StorageService,
    private emailComposer: EmailComposer,
    public serviceForAllService: ServiceforallService,
    public modalController: ModalController,
    public alertController :AlertController ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  ionViewWillEnter() {

    this.storage.getFromNativeStorage('user').then((user:any) => {
      user = JSON.parse(user);
      console.log(" USER LOGIN " + JSON.stringify(user));
      if (user != null) {
        this.user = user;
        // this.allServicesService.SaveAutoConfiqure(this.user.token);
      } else {
       
      }
    }, err => {
    });
  }
  Share(t:any) {

    if (t == "facebook") {
      this.iab.create('https://www.facebook.com/dialog/share?app_id=231290878024293&display=popup&href=' + this.url + '&redirect_uri=' + this.url, "_system")
      if(this.user_id){
        this.SendPushNotification();
      }
    }

    if (t == "twitter") {
      this.message = this.message + " " + this.url;
      this.iab.create('http://twitter.com/share?text=' + this.message + '&url=' + this.url, "_system")
      if(this.user_id){
        this.SendPushNotification();
      }
    }

    if (t == "mail") {
      let email = {
        to: '',
        subject: "Tell a Friend",
        body: this.message + " /n "+this.url,
        isHtml: true
      }
      this.emailComposer.open(email);
      if(this.user_id){
        this.SendPushNotification();
      }
    }

    if (t == "LinkedIn") {
      this.iab.create('https://www.linkedin.com/shareArticle?mini=true&url=' + this.url + '&title=RoosterCRM&summary=' + this.message + '&source=LinkedIn', "_system")
      if(this.user_id){
        this.SendPushNotification();
      }
    }

    if (t == "text") {
      this.presentAlertPrompt();  
      if(this.user_id){
        this.SendPushNotification();
      }
    }
   
   
  }

  SendPushNotification(){
    let formValue = {
      user_id:this.user_id,
      token:this.user.token
     };
   // this.serviceForAllService.showLoader("loading...");
    this.serviceForAllService.sendData('SendSharePushNotification', formValue).subscribe(
      result => {
        let rs: any = [];
        rs = result;
        console.log("result here", result);
        //this.dismissLoading();
        if (rs.status == "ok") {
          //this.serviceForAllService.presentAlert(rs.msg);
         // this.serviceForAllService.dismissLoading();
        }
      },
      err => {
        console.log("Error  == ", err);
        //this.serviceForAllService.dismissLoading();
        if (err.error.error_code == "user_expire") {
          this.storage.clearNativeStorage();
        }
       // this.serviceForAllService.presentAlert(err.error.errormsg);

      })
  }
  async SendTextRefrer(phone:any){
    let formValue = {
      phone:phone,
      url:this.url,
      token:this.user.token
     };
   this.serviceForAllService.showLoader("loading...");
    this.serviceForAllService.sendData('SendTextRefrer', formValue).subscribe(
      result => {
        let rs: any = [];
        rs = result;
        console.log("result here", result);
        //this.dismissLoading();
        if (rs.status == "ok") {

          this.serviceForAllService.presentAlert(rs.msg);
          this.serviceForAllService.dismissLoading();
          this.SendPushNotification();
        }
      },
      err => {
        console.log("Error  == ", err);
        this.serviceForAllService.dismissLoading();
        if (err.error.error_code == "user_expire") {
          this.storage.clearNativeStorage();
        }
       this.serviceForAllService.presentAlert(err.error.errormsg);

      })
  }


  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Share Via Text',
      inputs: [
        {
          name: 'phone',
          type: "tel",
          placeholder: 'Mobile Number',
          
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Share',
          handler: (data) => {
            console.log(data);
            if(data.phone){
            this.SendTextRefrer(data.phone);
            }else{
              this.presentAlertPrompt();
            }
          }
        }
      ]
    });

    await alert.present();
  }

}
