import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { StorageService } from "../services/storage.service";
import {
  ModalController,
  MenuController,
  LoadingController,
  AlertController,
  NavController, 
  Platform,
} from "@ionic/angular";
import { Router, ActivatedRoute } from "@angular/router";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { ServiceforallService } from "../serviceforall.service";
import { ActionSheetController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { SubscriptionService } from "../subscription.service";
import 'cordova-plugin-purchase';
declare var CdvPurchase: any



@Component({
  selector: "app-manage-subcription",
  templateUrl: "./manage-subcription.page.html",
  styleUrls: ["./manage-subcription.page.scss"],
})
export class ManageSubcriptionPage implements OnInit {
  username: any;
  lastname: any;
  profileimage: any;
  referenceuserid: any;
  loading: any;
  profile: any;
  fromandroid: boolean = false;

  plans: any;
  isdataloaded: boolean = false;
  error_message: any;
  loggedinuserid: any;
  displayName: any;
  role: any;
  res: any = [];
  purchasedsubscription: any;
  newtranscationid: any;
  ispmpromembershipset: any;

  deviceType: any;
  order_status: boolean = false;


  constructor(
    public cd: ChangeDetectorRef,
    public platform: Platform,
    private modalCtrl: ModalController,
    public navController: NavController,
    public storage: StorageService,
    public loadingCtrl: LoadingController,
    public router: Router,
    public alertCtrl: AlertController,
    private route: ActivatedRoute,
    public allServices: ServiceforallService,
    public actionSheetController: ActionSheetController,
    public http: HttpClient,
    public subscriptionService: SubscriptionService
    // private iap: InAppPurchase
  ) {
    if (this.platform.is('ios')) {
      this.deviceType = 'ios';
    } else {
      this.deviceType = 'android';
    }
    this.getProducts();
  }

  ngOnInit() { }

  ionViewWillEnter() {
   // this.registerPlan();

   this.storage.getFromNativeStorage("user").then((val: any) => {
    val = JSON.parse(val);
    if (val) {
      this.showLoader();
      this.allServices.getAvailablePlans(val.token).subscribe(
        (result) => {
          this.res = result;
          this.profileimage = this.res.userImage;
          this.displayName = this.res.display_name;
          this.role = this.res.role;
          this.loading.dismiss();
        },
        (err) => {
          this.loading.dismiss();
        }
      );
    }
  });

  }




  async showLoader() {
    this.loading = await this.loadingCtrl.create({
      message: "Please wait...",
      cssClass: "custom-load",
    });
    this.loading.present();
  }

  managesubscription(subscriptionplan: any) {
    this.showLoader();
    console.log("Subscription Plan=" + subscriptionplan);
    this.subscribePlan(subscriptionplan);
  }


  
  subscribePlan(single_plan: any) {

    const offer = single_plan.offers[0];
    console.log("OFFER :: ", JSON.stringify(offer));
    CdvPurchase.store.order(offer).then((p: any) => {

      this.order_status = true;
      this.setupListener(single_plan);
      this.loading.dismiss();
      console.log('order_status ::[]', this.order_status)
      console.log('Purcha ', JSON.stringify(p));

    }, (error: any) => {
      this.loading.dismiss();
      console.log('Failed to purchase.', error);
    });
  }


  setupListener(single_plan: any) {
    CdvPurchase.store.when().approved((product: any) => {
      console.log("APPROVED ::", product);
      if (product.products && product.products[0] && this.order_status) {
        product.finish();
        return;
      }
      if (product.state === 'approved') {
        product.verify();
      }
    }).verified((product: any) => {
      console.log("VERIFIED ::", product)
      product.finish();

    }).finished((f: any) => {
      console.log("FINISHED ::", f)
      this.loading.dismiss();
      if (f.transactionId != null || f.transactionId != undefined) {
        if (this.order_status) {
          console.log('this.order_status', this.order_status)
          this.updatesubscription(f, single_plan);
        }
      } else {
        console.log("TransactionId is not found!");
      }
    }).unverified((err: any) => {
      this.order_status = false;
      this.loading.dismiss();
      console.error('Error Unverified !!', JSON.stringify(err));
    });
  }



  updatesubscription(data: any, subscriptionplan: any) {
    console.log(data);
    // this.loading.dismiss();
    this.storage.getFromNativeStorage("user").then((val: any) => {
      val = JSON.parse(val);
      if (val) {
        if (this.platform.is("android")) {
          this.fromandroid = true;
        }

        let transaction_id: any;
        let orderid: any;
        let subscriptionplan_id: any;
        let receipt: any;
  
        if (this.platform.is('android')) {
          console.log('Android ------');
          transaction_id = data.transactionId;
          orderid = data.transactionId;
          receipt = data.nativePurchase.receipt;
          subscriptionplan_id = data.nativePurchase.productId;
        } else {
          console.log('IOS-------');
          transaction_id = data.transactionId;
          orderid = data.transactionId;
          receipt = data.parentReceipt.nativeData['appStoreReceipt'];
          subscriptionplan_id = data.products[0]['id']
        }

        console.log('transaction_id', transaction_id);
        console.log('fromandroid', this.fromandroid)
        console.log('subscriptionplan_id', subscriptionplan_id);
        console.log('receipt', receipt);
        console.log('orderid', orderid);



        this.allServices
          .createSubscription(
            val.token,
            transaction_id,
            receipt,
            subscriptionplan_id,
            this.fromandroid
          )
          .subscribe(
            (result) => {
              this.loading.dismiss();
              this.presentAlert2("Subscribed successfully");
              console.log("I am here after updating table");
            },
            (err) => {
              this.loading.dismiss();
              console.log("I am in here ");
            }
          );
      }
    });
  }

  restoresubscription() {
    console.log("I am here to restore the subscription");
  }


  async presentAlert(msg: any) {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: msg,
      buttons: ["OK"],
    });

    await alert.present();
  }
  async presentAlert2(msg: any) {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "Okay",
          handler: () => {
            this.navController.setDirection("root");
            this.router.navigate(["/tabs/home"]);
            this.modalCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }
  async DoLogout() {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: "Are you sure?",
      buttons: [
        {
          text: "Yes",
          handler: () => {
            this.storage.removeFromNativeStorage("user");
            this.storage.clearNativeStorage();
            this.navController.setDirection("root");
            this.router.navigate(["/login"]);
            this.modalCtrl.dismiss();
          },
        },
        {
          text: "No",
          role: "cancel",
          handler: () => { },
        },
      ],
      cssClass: "comment-alert",
    });

    await alert.present();
  }

  async getProducts() {
    this.platform.ready().then(() => {
      CdvPurchase.store.ready(() => {
        // CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
        console.log(' this.products ::', CdvPurchase.store.products);
        let products: any = CdvPurchase.store.products;
        if (products.length > 0) {
          this.plans = CdvPurchase.store.products;
          this.isdataloaded=true;
          
            console.log('subscriptionPlans:', this.plans);
        }
        console.log('subscriptionPlans:', this.plans);
      });
    });
  }


}
