import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signup/:invitation_token',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signup/:invitation_token/:type',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'new',
    loadChildren: () => import('./new/new.module').then( m => m.NewPageModule)
  },
  {
    path: 'redo',
    loadChildren: () => import('./redo/redo.module').then( m => m.RedoPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'privacy-statement',
    loadChildren: () => import('./privacy-statement/privacy-statement.module').then( m => m.PrivacyStatementPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'forgot-email',
    loadChildren: () => import('./forgot-email/forgot-email.module').then( m => m.ForgotEmailPageModule)
  },
  {
    path: 'forgot-mobile',
    loadChildren: () => import('./forgot-mobile/forgot-mobile.module').then( m => m.ForgotMobilePageModule)
  },
  {
    path: 'personal-info',
    loadChildren: () => import('./personal-info/personal-info.module').then( m => m.PersonalInfoPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'vendors',
    loadChildren: () => import('./vendors/vendors.module').then( m => m.VendorsPageModule)
  },
  {
    path: 'sales-reps',
    loadChildren: () => import('./sales-reps/sales-reps.module').then( m => m.SalesRepsPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'vendoradd/:vendor_id',
    loadChildren: () => import('./vendoradd/vendoradd.module').then( m => m.VendoraddPageModule)
  },
  {
    path: 'clientadd',
    loadChildren: () => import('./clientadd/clientadd.module').then( m => m.ClientaddPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'schedule-message',
    loadChildren: () => import('./schedule-message/schedule-message.module').then( m => m.ScheduleMessagePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'managemessage',
    loadChildren: () => import('./managemessage/managemessage.module').then( m => m.ManagemessagePageModule)
  },
  {
    path: 'client/:client_id',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'lifeevent/:client_id/:id/:via_route/:type',
    loadChildren: () => import('./lifeevent/lifeevent.module').then( m => m.LifeeventPageModule)
  },
  {
    path: 'newmessage/:id',
    loadChildren: () => import('./newmessage/newmessage.module').then( m => m.NewmessagePageModule)
  },
  {
    path: 'clientselect',
    loadChildren: () => import('./clientselect/clientselect.module').then( m => m.ClientselectPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'clientnew',
    loadChildren: () => import('./clientnew/clientnew.module').then( m => m.ClientnewPageModule)
  },
  {
    path: 'clientmodal',
    loadChildren: () => import('./clientmodal/clientmodal.module').then( m => m.ClientmodalPageModule)
  },
  {
    path: 'clientprofile/:client_id',
    loadChildren: () => import('./clientprofile/clientprofile.module').then( m => m.ClientprofilePageModule)
  },
  {
    path: 'vendorprofile/:vendor_id',
    loadChildren: () => import('./vendorprofile/vendorprofile.module').then( m => m.VendorprofilePageModule)
  },
  {
    path: 'vendorprofile/:vendor_id/:by_url',
    loadChildren: () => import('./vendorprofile/vendorprofile.module').then( m => m.VendorprofilePageModule)
  },
  {
    path: 'connectsp/:connecting_token',
    loadChildren: () => import('./connectsp/connectsp.module').then( m => m.ConnectspPageModule)
  },
  { 
    path: 'spprofile/:sp_id',
    loadChildren: () => import('./spprofile/spprofile.module').then( m => m.SpprofilePageModule)
  },
  {
    path: 'spprofile/:sp_id/:type',
    loadChildren: () => import('./spprofile/spprofile.module').then( m => m.SpprofilePageModule)
  },
  {
    path: 'vendorlistclient/:sp_id',
    loadChildren: () => import('./vendorlistclient/vendorlistclient.module').then( m => m.VendorlistclientPageModule)
  },
  {
    path: 'signature',
    loadChildren: () => import('./signature/signature.module').then( m => m.SignaturePageModule)
  },
  {
    path: 'sharemodal',
    loadChildren: () => import('./sharemodal/sharemodal.module').then( m => m.SharemodalPageModule)
  },
  {
    path: 'mymessage',
    loadChildren: () => import('./mymessage/mymessage.module').then( m => m.MymessagePageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'vendoraddoption',
    loadChildren: () => import('./vendoraddoption/vendoraddoption.module').then( m => m.VendoraddoptionPageModule)
  },
  {
    path: 'appointmentdetails/:apt_id',
    loadChildren: () => import('./appointmentdetails/appointmentdetails.module').then( m => m.AppointmentdetailsPageModule)
  },
  {
    path: 'app-download',
    loadChildren: () => import('./app-download/app-download.module').then( m => m.AppDownloadPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'manage-subcription',
    loadChildren: () => import('./manage-subcription/manage-subcription.module').then( m => m.ManageSubcriptionPageModule)
  },
  {
    path: 'holiday-messages',
    loadChildren: () => import('./holiday-messages/holiday-messages.module').then( m => m.HolidayMessagesPageModule)
  },
  {
    path: 'updated-message',
    loadChildren: () => import('./updated-message/updated-message.module').then( m => m.UpdatedMessagePageModule)
  },
  {
    path: 'forgot-mobile2',
    loadChildren: () => import('./forgot-mobile2/forgot-mobile2.module').then( m => m.ForgotMobile2PageModule)
  },
  {
    path: 'my-documents',
    loadChildren: () => import('./my-documents/my-documents.module').then( m => m.MyDocumentsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
