import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  MenuController,
  NavParams,
  ModalController,
} from "@ionic/angular";

import { StorageService } from '../services/storage.service';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,

} from "@angular/forms";
import { Router } from "@angular/router";
import { ServiceforallService } from '../serviceforall.service';
import { GlobalEventService } from '../globalservice';

@Component({
  selector: 'app-clientmodal',
  templateUrl: './clientmodal.page.html',
  styleUrls: ['./clientmodal.page.scss'],
})
export class ClientmodalPage implements OnInit {
  user: any;

  posts :any= [];
  page = 1;
  count = null;
  likecount: any;
  result: any;
  ready: boolean = false;
  user_data: any;
  user_name: any;
  selected_users: any = [];
  users_name: any = [];

  selected_users_2: any = [];
  users_name_2: any = [];
  constructor(public serviceforallService: ServiceforallService,
    public loadingCtrl: LoadingController,
    public router: Router,
    public alertCtrl: AlertController,
    public storage: StorageService,
    public modalCtrl: ModalController,
    navParams: NavParams,
    public menu: MenuController,
    private globalEventService: GlobalEventService,
    public navController: NavController, ) {
    this.selected_users = navParams.get('selected_users');
  }

  ngOnInit() {
    // this.events.subscribe('loadVendors', (user) => {
    //   this.loadPosts();
    // });

  }

  ionViewWillEnter() {

    this.storage.getFromNativeStorage('user').then((user:any) => {
      console.log(" USER LOGIN " + JSON.stringify(user));
      user = JSON.parse(user);
      if (user != null) {
        this.user = user;
        this.loadPosts();
        // this.allServicesService.SaveAutoConfiqure(this.user.token);
      }
    }, err => {

    });
  }

  async loadPosts(user_name:any='') {
    this.page=1;
    this.user_name=user_name;
    let formValue = {
      token:this.user.token,
      page:this.page
    }
    this.serviceforallService.sendData('GetClientList', formValue).subscribe(
      result => {
        let rs: any = [];
        rs = result;
        console.log("result here", result);
        this.ready=true;
        //this.dismissLoading();
        if (rs.status == "ok") {
          this.posts=rs.users;
          this.posts.forEach((u:any) => {
            this.users_name[u.id] = u.first_name;
          });
        }
      },
      err => {
        this.ready=true;
        this.posts=[];
        console.log("Error  == ", err);
        if(err.error.error_code=="user_token_expired"){
          this.storage.clearNativeStorage();
          this.router.navigate(['/login'])
        }
       
      }
    );
  }

  loadMore(event:any) {
    this.page++;
 
    let formValue = {
      token:this.user.token,
      page:this.page
    }
    this.serviceforallService.sendData('GetClientList', formValue).subscribe(
      result => {
        let rs: any = [];
        rs = result;
        console.log("result here", result);
        this.ready=true;
        //this.dismissLoading();
        if (rs.status == "ok") {

          
         
          this.posts = [...this.posts, ...rs.users];
          this.posts.forEach((u:any) => {
            this.users_name[u.id] = u.first_name;
          });
          event.target.complete();
        }
      },
      err => {
        this.ready=true;
        console.log("Error  == ", err);
        if(err.error.error_code=="user_token_expired"){
          this.storage.clearNativeStorage();
          this.router.navigate(['/login'])
        }else{
          this.page--;
          event.target.complete();
        }
       
      }
    );
  }

  insertUserToArray(ev:any) {
    console.log('Segment changed', ev);
    if (ev.detail.checked == false) {
      //this.selected_users.splice(ev.detail.value, 1); 
      delete this.selected_users[ev.detail.value];
      console.log(this.selected_users);
      console.log(this.users_name);

    } else {
      this.selected_users[ev.detail.value];
      console.log(this.selected_users);
    }
  }
 

  ModalDismiss(type:any) {
    if(type==1){
      this.modalCtrl.dismiss({
        selected_users: this.selected_users,
        users_name: this.users_name
      });
    }else{
      this.modalCtrl.dismiss({
        selected_users:[],
        users_name:[]
      });
    }
  
  }
}

