import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import 'cordova-plugin-purchase';
declare var CdvPurchase: any

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private platform: Platform
  ) { }

  registerSubscriptionProducts(products :any): Promise<any> {
    return new Promise(async (resolve, reject) => {
    this.platform.ready().then(() => {
      CdvPurchase.store.error((err:any) => {console.error('STORE ERROR : ' + err);});
      console.log('products:', products); 
      products.forEach((product_id:any) => {
          console.log('product_id:', product_id); 
          if(this.platform.is('android')){
            CdvPurchase.store.register({
              id: product_id,
              type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
              platform: CdvPurchase.Platform.GOOGLE_PLAY
            });
            resolve(true);
          }else{
              CdvPurchase.store.register({
                id: product_id,
                type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                platform: CdvPurchase.Platform.APPLE_APPSTORE
              });
              resolve(true);
          }
      });
      CdvPurchase.store.initialize();
    });
  })
  }




}
