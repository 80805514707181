import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertControllerService {

    constructor(
        private alertController: AlertController,
        public loadingCtrl: LoadingController,
        public toastController: ToastController
    ) { }


    presentAlert(alertMessage: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                cssClass: 'artstock_alert_2',
                message: alertMessage,
                mode: 'ios',
                backdropDismiss: false,


                buttons: ['OK']
            });

            await alert.present();
        });
    }

    presentAlertWithHeader(alertHeader: string, alertMessage: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                cssClass: 'artstock_alert_2',
                header: alertHeader,
                message: alertMessage,
                mode: 'ios',
                buttons: ['OK']
            });

            await alert.present();
        });
    }

    presentAlertWithButtons(alertMessage: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                cssClass: 'artstock_alert_2',
                message: alertMessage,
                mode: 'ios',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => resolve(true)
                    }
                ]
            });

            await alert.present();
        });
    }

    showLoader(msg: any = '') {
        if (msg == '') {
            msg = "Loading...";
        }
        this.loadingCtrl.create({
            message: msg
        }).then((response) => {
            response.present();
        });
    }
    // Dismiss loader
    dismissLoader() {
        this.loadingCtrl.dismiss().then((response) => {
            console.log('Loader closed!', response);
        }).catch((err) => {
            console.log('Error occured : ', err);
        });
    }

    presentAlertWithnumbers(alertMessage: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                cssClass: 'artstock_alert_2',
                message: alertMessage,
                mode: 'ios',
                buttons: [
                    {
                        text: '1 Nut for 1 Attempt',
                        handler: () => resolve(1)
                    }, {
                        text: '5 Nuts for 5 Attempts',
                        handler: () => resolve(5)
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => resolve(0)
                    }
                ]
            });

            await alert.present();
        });
    }

    async presentToast(msg:any='') {
        const toast = await this.toastController.create({
            message: msg,
            duration: 5000,
            position: 'bottom'
        });
        toast.present();
    }


  persentAlerWithConfirmation(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithOk(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Ok',
            handler: () => resolve(true)
          }
        ]
      });
      await alert.present();
    });
  }

}


