import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { AlertController, LoadingController,NavController } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin'; 
import { Globalization } from '@ionic-native/globalization/ngx';
import { Platform } from '@ionic/angular';
import { StorageService } from './services/storage.service';
import * as firebase from 'firebase';
let wpUrl = 'https://roostercrm.com/';
let fullUrl = 'https://roostercrm.com/wp-json/mobileapi/v1/';
let url = 'https://roostercrm.com/wp-json/wp/v2/';

@Injectable({
  providedIn: 'root'
})
export class ServiceforallService {
  loading: any;
  iana_timezone: any;
  one_id: any;
  token: any;
  isLoading = false;
  has_plan: boolean = false;
  totalPosts = null;  
  pages: any;
  plans: any;
  constructor(
    public navCtrl:NavController,
    private http: HttpClient,
    public loadingCtrl: LoadingController,
    private globalization: Globalization,
    private platform: Platform,
    public alertCtrl: AlertController,
    private storage: StorageService
  ) { }


  getURL() {
    return wpUrl;
  }

  async presentAlert(msg:any,redirect:boolean=false) {
    let alert = await this.alertCtrl.create({
      header: 'Rooster CRM',
      backdropDismiss:false,
      message: msg,
      buttons: [{
        text: 'OK',
        handler: () => {
          if(redirect){
            this.navCtrl.pop();
          }
        }
      }]
    });

    await alert.present();
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('loading dismissed'));
  }

  async showLoader(msg: string = '') {
    if (msg == '') {
      msg = 'Please wait...';
    }
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: msg,
      spinner: 'circles'
    }).then(a => {
      a.present().then(() => {
        console.log('loading presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  doLogin(email:any, password:any) {
    console.log("SERVICE == " + email);
    return this.http.post(wpUrl + 'wp-json/jwt-auth/v1/token', {
      username: email,
      password: password,
      jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
    })
  }


  sendData(endPoint:any, data:any) {
    console.log(" URL ===============================" + fullUrl + endPoint, " DATA  ==== ", data);
    return this.http.post(fullUrl + endPoint, data).pipe(
      map(data => {
        return data;
      })
    )

  }

  saveOneSignID(token:any, oneSignID:any) {
    let device:any='ios';
    if (this.platform.is('ios')) {
      device = 'ios';
    } else {
      device = 'android';
    }
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/save_onesignal_id', {
      oneSignID: oneSignID,
      token: token,
      type: device,
      timezone: timezone
    });
  }

  async SaveAutoConfiqure(token:any) {
    console.log(token);
    if (this.platform.is('cordova')) {

      OneSignal.getDeviceState((res: any) => {
        console.log("res: ", res);
        let temp: any = res;
        this.one_id = temp.userId;
        this.token = token;
        this.saveOneSignID(this.token, this.one_id).subscribe(m => {
        });

      });
    }

  }



  getPosts(post_type: any, page = 1, userToken: any = '', mypost: number = 1, c: number = 0, name: any = "allreport", sp_id: number = 0): Observable<any[]> {
    let category_url = c ? ("&service_category=" + c) : "";

    let options = {
      observe: "response" as 'body',
      params: {
        per_page: '10',
        page: '' + page
      }
    };

    return this.http.get<any[]>(url + post_type + '?_embed&token=' + userToken + "&mypost=" + mypost + "&name=" + name + "&sp_id=" + sp_id + category_url, options).pipe(
      map((resp:any) => {
        this.pages = resp['headers'].get('x-wp-totalpages');
        this.totalPosts = resp['headers'].get('x-wp-total');

        let data = resp['body'];

        for (let post of data) {
          post.media_url = post['media_url'];
        }
        return data;
      })
    )
  }



  doFBSignup(obj:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/facebook_login', {
      email: obj.email,
      nickname: obj.email,
      username: obj.email,
      id: obj.id,
      fbname: obj.name,
      type: obj.type,
      password: ""
    }).pipe(
      retry(2),
      map(content => {
        return content;
      })
    )
  }

  getUser(token:any, type: number = 0, sp_id: number = 0) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/GetUser', {
      token: token,
      type: type,
      sp_id: sp_id
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  doUpdatePassword(userData:any, token:any) {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/change_password", {
      oldPassword: userData.oldPassword,
      password: userData.password,
      token: token
    });
  }


  getPostContent(id:any, type:any) {
    return this.http.get(url + type + '/' + id + '?_embed').pipe(
      map((post:any) => {
        post['media_url'] = post['media_url'];
        return post;
      })
    )
  }

  getClient(token:any, id:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getClient', {
      token: token,
      id: id
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  getVendor(token:any, id:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getVendor', {
      token: token,
      id: id
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  updateProfile(user_data:any, token:any) {
    console.log("SERVICES === ", user_data);
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/doProfileUpdate', {
      token: token,
      user_data: user_data
    })
  }

  getCurrentUserInfo(token:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getCurrentUserInfo', {
      token: token
    })
  }

  getSecoondUserInfo1(token:any, id:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getSecoondUserInfo1', {
      token: token,
      id: id,

    })
  }

  updateProfileClient(user_data:any, token:any, id:any) {
    console.log("SERVICES === ", user_data);
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/updateProfileClient', {
      token: token,
      user_data: user_data,
      id: id
    })
  }

  getSecoondUserInfo(token:any, id:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getSecoondUserInfo', {
      token: token,
      id: id
    })
  }

  getData(endPoint:any) {
    return this.http.get(fullUrl + endPoint).pipe(
      map(data => {
        return data;
      })
    )
  }

  submitForm(formId:any, formdata:any, user_id:any, user_token:any) {
    return this.http.post(fullUrl + "submitForm", {
      formId: formId,
      formdata: formdata,
      user_id: user_id,
      user_token: user_token
    })
  }

  getMemberLevel(user_id:any) {
    return this.http.get(wpUrl + 'wp-json/pmpro/v1/getlevelforuser/' + user_id).pipe(
      map(user => {
        return user;
      })
    )
  }


  CheckUserMembership() {
    this.storage.getFromNativeStorage('user').then((user:any) => {
      user = JSON.parse(user);
      this.getMemberLevel(user.user_id).subscribe(res => {
        //this.count = this.serviceForAllService.totalPosts;
        console.log(res);
        this.plans = res;
        this.has_plan = this.plans.membership;
      }, err => {
      });
    });
  }
  getAvailablePlans(token:any) {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/getAvailablePlans", {
      token: token,
    })
  }
  createSubscription(token:any, transcationid:any, receipt:any, plan:any, fromandroid:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/create_user_subscription', {
      token: token,
      transcationid: transcationid,
      receipt: receipt,
      fromandroid: fromandroid,
      plan: plan
    })
  }
  verifyRecipt(token:any, fromandroid:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/verifyRecipt', {
      token: token,
      fromandroid: fromandroid
    })
  }
  updateTranscationId(token:any, transcationid:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/update_transcation', {
      token: token,
      transcationid: transcationid
    })
  }
  deleteClientOrVendor(token:any, id:any,action_type:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/deleteClientOrVendor', {
      token: token,
      id: id,
      action_type:action_type
    })
  }

  deleteClientOrVendorBulk(token:any,ids:any,action_type:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/deleteClientOrVendorBulk', {
      token: token,
      ids: ids,
      action_type:action_type
    })
  }
  getHolidayMessages(token:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getHolidayMessages', {
      token: token
    })
  }
  getHolidaySingleMessages(token:any,post_id:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/getHolidaySingleMessages', {
      token: token,
      post_ID:post_id
    })
  }
  
  updateHolidayMessages(token:any,post_ID:any,values:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/updateHolidayMessages', {
      token: token,
      post_ID:post_ID,
      values:values
    })
  }

  getMyDocument(token:any,userID:any='self') {
    return this.http.post(wpUrl + "wp-json/mobileapi/v1/getMyDocument", {
      token: token,
      userID:userID
    });
  }

  upload_image(data:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/upload_document', data).pipe(
      map(data => {
        return data;
      })
    )
  }

  deleteDoc(token:any,index:any,otherClient:any='self') {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/deleteDoc', {
      token: token,
      index:index,
      otherClient:otherClient
    })
  }
  // verifyRecoveryCode(recovery_code, new_password,confirm_new_password) {
  //   return this.http.post(wpUrl + 'wp-json/mobileapi/v1/verify_recovery_code', {
  //     recovery_code: recovery_code,
  //     new_password: new_password,
  //     confirm_new_password:confirm_new_password
  //   })
  // }


  sendPushNotification(id:any, msg:any, name:any, senderID:any) {
    return this.http.post(wpUrl + 'wp-json/mobileapi/v1/sendPushNotification', {
      jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$",
      id: id,
      msg: msg,
      name: name,
      senderID: senderID
    })

  }
  firebaseAuthToken(email:any='mainuser@knoxweb.com',password:any='sR123456'){

    firebase.auth().signInWithEmailAndPassword(email,password).then((user)=> {
      
      console.log('firebase login = ',{user});
  
        }).catch((err:any=[])=> {
  
          if(err.code=='auth/user-not-found'){
            firebase.auth().createUserWithEmailAndPassword(email,password).then((user)=> {
              console.log('firebase account created = ',{user});
              this.firebaseAuthToken(email,password);
            });
          }
  
        });
  }
  
  firebaseSignOut(){
    firebase.auth().signOut();
  }
  
}
