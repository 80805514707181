import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { IonicStorageModule } from '@ionic/storage'; 
import { HttpClientModule } from '@angular/common/http';
import {ManageSubcriptionPageModule} from './manage-subcription/manage-subcription.module';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/Camera/ngx';

import { Globalization } from '@ionic-native/globalization/ngx';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ClientmodalPageModule } from './clientmodal/clientmodal.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharemodalPageModule } from './sharemodal/sharemodal.module';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { TextMaskModule } from 'angular2-text-mask';

import { FilePath } from '@ionic-native/file-path/ngx';

import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';





@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,TextMaskModule, HttpClientModule,IonicModule.forRoot({
    animated:false
  }), AppRoutingModule,ClientmodalPageModule,SharemodalPageModule,ManageSubcriptionPageModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    NativeStorage,
    SplashScreen,
    Camera,
    File,
    FilePath,
    FileTransfer,
    // Facebook,
    // GooglePlus,
    ActionSheet,
    Globalization,
    // OneSignal,
    Toast,
    InAppBrowser,
    EmailComposer,
    IOSFilePicker,
    FileChooser,
    FilePath,
    SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
