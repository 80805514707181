import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';



import 'cordova-plugin-purchase';
import { Events } from './events';

declare var CdvPurchase: any

@Injectable({
  providedIn: 'root'
})
export class IpaService {
  store?: CdvPurchase.Store;

  // private purchaseSuccessCallback: (product: CdvPurchase.VerifiedReceipt) => void;
  // private purchaseErrorCallback: () => void;
  // private cancelSuccessCallback: () => void;

  plans: any = ['io.roostercrm.user'];
  products :any = [];
  constructor(
    private platform: Platform,
    private events : Events,
  ) { 

  }
  registerProducts() {
    console.log("registerProducts plans", this.plans);
    return new Promise<void>((resolve, reject) => {
      this.plans.forEach((productId:any) => {
        if(this.platform.is('android')){
          CdvPurchase.store.register({
            id: productId,
            type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
            platform: CdvPurchase.Platform.GOOGLE_PLAY
          });
        }else{
            CdvPurchase.store.register({
              id: productId,
              type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
              platform: CdvPurchase.Platform.APPLE_APPSTORE
            });
        }
      });

      resolve();
    })
  }




  getProducts(): Promise<CdvPurchase.Product[]> {
    console.log('inside get');
    return new Promise<CdvPurchase.Product[]>((resolve) => {
      console.log("getProducts");
      resolve(CdvPurchase.store.products);
      this.products = CdvPurchase.store.products;
      console.log("Membership IAP products resolved", CdvPurchase.store.products);
    });
  }

  setupListeners() {
    return new Promise<void>((resolve, reject) => {
    CdvPurchase.store.when()
      .approved((transaction: CdvPurchase.Transaction) => {
        console.log("approved",transaction);
        transaction.verify();
        console.log("transaction approved",JSON.stringify(transaction));
      })
      .finished((transaction: CdvPurchase.Transaction) => {
        console.log("finished",transaction);
        transaction.finish();
        this.events.publish('transactionFinished',transaction);
        console.log("transaction finished",JSON.stringify(transaction));
      })
      .pending((transaction:any) => {
        console.log("pending",transaction);
        transaction.verify();
        console.log("transaction pending",JSON.stringify(transaction));
      })
      .productUpdated((product:any) => {
        console.log("productUpdated",product);

      })
      .receiptUpdated((recepit:any) => {
        console.log("receiptUpdated",recepit);
        this.events.publish('receiptUpdated',recepit);
        console.log('receipt updated--',JSON.stringify(recepit));

      })
      .receiptsReady((receipt:any) => {
        console.log("receiptsReady",receipt);
        console.log('receipt ready--',JSON.stringify(receipt));

      })
      .receiptsVerified((receipt:any) => {
        console.log("receiptsVerified--",receipt);
        this.events.publish('receiptUpdated',receipt);
       
        console.log('receipt verified--',JSON.stringify(receipt));
      })
      .unverified((receipt:any) => {
        console.log("unverified--",receipt);
        console.log("unverified s--",JSON.stringify(receipt));

      })
      .verified((receipt: CdvPurchase.VerifiedReceipt) => {
        console.log("verified--");
        receipt.finish();
        console.log('receipt:>>',receipt);
        console.log('receipt s:>>',JSON.stringify(receipt));
        // this.purchaseSuccessCallback(receipt);
      });

    CdvPurchase.store.error((error:any) => {
      console.error("in app store error", error);
    });
    resolve()

  });

  }

  initialize() {
    console.log("InAppStoreService initialize");
    this.platform.ready().then(() => {
      CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
      console.log("InAppStoreService platform.ready");

      CdvPurchase.store.ready(() => {
        console.log("InAppStoreService store.ready callback", CdvPurchase.store.isReady);
      });

      this.setupListeners();
      this.registerProducts();
      console.log("InAppStoreService store.products", CdvPurchase.store.products);
      console.log("InAppStoreService store.ready", CdvPurchase.store.isReady);

      CdvPurchase.store.initialize().then((error:any) => {
        console.error("InAppStoreService initialize then error", error);
      }).catch((error:any) => {
        console.error("InAppStoreService initialize catch error", error);
      });

      console.log("InAppStoreService store.ready after init", CdvPurchase.store.isReady);
    });
  }



  


}
