import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ManageSubcriptionPageRoutingModule } from './manage-subcription-routing.module';

import { ManageSubcriptionPage } from './manage-subcription.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ManageSubcriptionPageRoutingModule
  ],
  declarations: [ManageSubcriptionPage]
})
export class ManageSubcriptionPageModule {}
